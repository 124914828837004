@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

body {
  font-family: "Nunito", sans-serif;
  background-color: #ecf0f1;
}

.carousel-control-prev {
  display: none;
}

.carousel-control-next {
  display: none;
}

::selection {
  background: #153e8b;
}

#codeSearchBtn:hover {
  background-color: #ff4116;
  /* transition: 0s; */
  color: white;
}

#codeSearchBtn:active {
  background-color: #ff2f00;
  color: white;
}
