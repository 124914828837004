.card:hover .hover {
  /* margin-top: 0; */

  height: 100%;
  /* transform: translate(0, -100%); */
}

.card:hover .hover {
  backdrop-filter: blur(3px);
}

.hover {
  position: absolute;
  /* height: 100%;
  width: 100%; */
  background-color: rgba(0, 0, 0, 0.6);
  /* top: 0; */
  bottom: 0;
  left: 0;
  right: 0;
  height: 0px;
  color: white;
  width: 100%;
  height: 0;
  transition: 500ms ease;
  overflow: auto;
}

.inner {
  /* background-color: red; */
  padding: 20px 10px;
  /* overflow: auto; */
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* margin-block: 10px; */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #255eab;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:active {
  background: #1c4983;
}
