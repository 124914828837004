.cardContainer:hover .cardInner {
  background-color: rgba(0, 0, 0, 0.5);
}

.cardInner {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  /* backdrop-filter: blur(3px); */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cardInner Button:hover {
  background-color: #ff4116;
  /* transition: 0s; */
}

.cardInner Button:active {
  background-color: #ff2f00;
}

.cardContainer:hover .cardInner button {
  visibility: visible;
  opacity: 1;
}
